$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font-family-gochi-hand: 'Gochi Hand', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-bg: #333;
$body-color: #333;

$headings-color: #dc1e1e;
$headings-font-weight: 300;
$headings-font-family: 'Oswald', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$primary: #dc1e1e;

$link-color: $primary;
$link-hover-color: $primary;

$navbar-padding-y: 0;
$navbar-padding-x: 0;

$navbar-nav-link-padding-x: 1rem;
$navbar-nav-link-padding-y: 12px;

$nav-tabs-link-active-bg: #004af3;

$navbar-light-color:                #fff;
$navbar-light-hover-color:          #fff;
$navbar-light-active-color:         #fff;
$navbar-light-disabled-color:       rgba(#fff, .3);

$navbar-link-bg: $primary;
$navbar-link-hover-bg: $nav-tabs-link-active-bg;
$navbar-link-active-bg: $nav-tabs-link-active-bg;

$hamburger-color: #fff;

$story-headings-color: #000;

@import "../../fonts/gochi-hand";
@import "../../fonts/oswald";
@import "../../magazine";

.brand-logo {
  img {
    height: auto;

    @include media-breakpoint-down(sm) {
      max-height: 40px;
    }
  }
}

.navbar {
  background-color: $primary;

  .nav-link {
    color: $white;
    font-family: $font-family-gochi-hand;

    @include media-breakpoint-up(md) {
      @include font-size(1rem);
    }

    @include hover-focus {
      background-color: $nav-tabs-link-active-bg;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar-top {
    background-color: $primary;
  }

  .navbar-nav .nav-link {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.navbar.navbar-expand  {
  .nav-item {
    @include nav-item-separator(#fff, $navbar-link-font-size);
  }
}

.navbar-collapse {
  &.collapsing,
  &.collapse.show {
    border-top: 1px solid #ddd;
  }
}

.sidebar .teaser-title {
  color: $body-color;
}

.sidebar .card.filled {
  background-color: #FAFAFA;

  .card-header {
     @include font-size(1rem);
    font-weight: $font-weight-normal;
  }
}

.section-caption {
  font-weight: $font-weight-normal;
  color: $black;
  @include font-size(1rem);
}

.article-head {
  h1, p {
    font-weight: $headings-font-weight;
  }
  p {
    color: #777;
    font-family: $headings-font-family;
  }
}

article .pagination {
  font-weight: $font-weight-bold;
}

.article-story {
  h3, h4 {
    color: $headings-color;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
  }

  h3 {
    @include font-size($font-size-base * 1.7);
  }

  h4 {
    @include font-size($font-size-base * 1.5);
  }
}
